<template>
  <div class="shantuantuan">
    <van-nav-bar
      title="闪团团收益"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="wallent_content" :style="'margin-top: ' + (screenWidth * 0.1) + 'px;'">
      <van-icon name="gold-coin" color="#FFC300" size="60" />
      <van-cell :border="false" title="可提现金额" title-class="content_text" />
      <van-cell :border="false" title-class="content_money">
        <template #title>
          <span>&yen;</span>{{money}}
        </template>
      </van-cell>
      <van-cell-group :border="false" :style="'margin-top: ' + (screenWidth * 0.15) + 'px;'">
        <van-cell :border="false">
          <template #title>
            <van-button type="danger" size="small" @click="tixian">提现至零钱</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <!-- 提现弹框 -->
    <van-dialog
      v-model="tixianShow"
      title="提现"
      :show-confirm-button="false"
      get-container=".shantuantuan"
    >
      <van-icon name="close" size="30" @click="tixianHidden" color="#999" class="cross_dialog" />
      <van-form validate-first @submit="submitTixian">
        <van-field
          label="到账账户"
          name="accountName"
          v-model="accountName"
          readonly
        />
        <van-field
          v-model="accountShiMing"
          name="accountShiMing"
          label="账户实名"
          placeholder="请输入您微信认证的实名"
          :rules="[{ validator: shimingRule, message: '', required: true }]"
        />
        <van-field
          v-model="txMoney"
          name="txMoney"
          label="提现金额"
          type="number"
          placeholder="请输入您微信认证的实名"
          :rules="[{ validator: txMoneyRule, message: '', required: true }]"
        />
        <van-cell :border="false">
          <template #title>
            当前可提现金额 &yen;{{money}}
            <van-tag color="#ffffff" text-color="#EE0A24" size="medium" @click="allMoney">全部提现</van-tag>
          </template>
        </van-cell>
        <div class="btn_list">
          <van-button block round type="danger" native-type="submit">确认提现</van-button>
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: "Shantuantuan",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      money: "0.00",
      tixianShow: false, // 是否显示提现金额
      accountName: '微信零钱',
      accountShiMing: '',
      txMoney: '', // 提现金额
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    // 实名校验规则
    shimingRule(val) {
      if (!val || val.trim().length <= 0) {
        this.$toast("请输入真实姓名");
        return false;
      }
      return true;
    },
    // 提现金额校验规则
    txMoneyRule(val) {
      if (!val || parseFloat(val.trim()) <= 0) {
        this.$toast("请输入提现金额");
        return false;
      }
      return true;
    },
    init() {
      console.log("init");
      this.getMoney(); // 获取可提现金额
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 获取可提现金额
    getMoney() {
      let that = null;
      that = this;

      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "stt/user/commission",
          that.$qs.stringify({
            type: 'yundian'
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.money = res.data.data.money;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 显示申请提现
    tixian() {
      if (parseInt(this.money) <= 0) {
        this.$toast("暂无可提现额度");
        return false;
      }
      this.tixianShow = true;
    },
    // 关闭提现弹框
    tixianHidden() {
      this.accountShiMing = ''; // 真实姓名
      this.txMoney = ''; // 提现金额
      this.tixianShow = false;
    },
    // 全部提现
    allMoney() {
      this.txMoney = parseFloat(this.money);
    },
    // 确认提现
    submitTixian(values) {
      if(!this.accountShiMing || this.accountShiMing.trim().length <= 0) {
        this.$toast("请输入真实姓名");
        return false;
      }
      if(!this.txMoney || parseFloat(this.txMoney.trim()) <= 0) {
        this.$toast("请输入提现金额");
        return false;
      }
      let that = null;
      that = this;

      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "stt/user/fundout",
          that.$qs.stringify({
            money: that.txMoney,
            realname: that.accountShiMing,
            source: 1,
            type: 1
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 0) {
            that.$toast(res.data.msg ? res.data.msg : "提现成功");
            that.tixianHidden();
            setTimeout(() => {
              that.$router.back();
            }, 100)
          }else {
            that.$toast(res.data.msg ? res.data.msg : "提现失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
  }
}
</script>
<style lang="less">
.shantuantuan {
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  &:last-child {
    padding-bottom: 50px;
  }
  .wallent_content{
    padding-bottom: 60px;
    overflow: hidden;
    .van-cell {
      line-height: 40px;
      .van-cell__title {
        flex: 1;
        text-align: center;
      }
      .content_text {
        padding-top: 20px;
        color: #333;
        font-size: 16px;
      }
      .content_money {
        color: #333;
        font-size: 40px;
        span {
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }
    .van-cell-group {
      .van-cell {
        .van-button {
          width: 200px;
          height: 46px;
          background-color: #DC4B3A;
          border-color: #DC4B3A;
          color: #fff;
          border-radius: 6px;
          font-size: 14px;
        }
      }
    }
  }

  /*弹框样式 Start*/
  .btn_list {
    margin: 16px;
  }
  .cross_dialog {
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: lighter;
  }
  /*弹框样式 End*/
}
</style>